import React from 'react'
import "../App.css"

const Uppskattning = (props) => {
    let k = props.data;
    let estimated_usage = 0;
    let estimated_cost = 0;

    for(let i = 0; i < k.length; i++){
        let curr = k[i]
        for(let h = 0; h < curr.hours.length; h++){
            let data = curr.hours[h];
            let usage = parseFloat((data.kwh).toFixed(4))
            let price = parseFloat((data.sek * 100).toFixed(4))


            estimated_usage+=usage;
            estimated_cost+=(price * usage)
        }
    }
    estimated_cost/=100;
    
	return(
        <div className='centered'>
	        <h3> {estimated_cost.toFixed(2)}kr</h3> <h3> {estimated_usage.toFixed(2)}kwh </h3> <h3> {(estimated_cost / estimated_usage).toFixed(2)} snitt</h3>
    </div>
	);
}

export default Uppskattning;
