import './App.css';
import Graph from "./pages/Graph";

import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={< Graph />} />


        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;
