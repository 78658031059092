import React, { useState } from "react";

import ReactApexChart from 'react-apexcharts'
import '../index.css';


export default function Grapher({usage, cost, dates, kwhLimit, costLimit}) {
 //const [date, setDate] = useState(new Date());

 const series = [{
    name: 'Elförbrukning',
    type: 'line',
    data: []
  }, {
    name: 'Elpris',
    type: 'line',
    data: []
  }]




const options = {

    chart: {
      height: 350,
      type: 'line',
      animations:{
        enabled:false
      }
    },
    stroke: {
      width: [3, 3],
      curve:'smooth'
    },
    title: {
      text: ''
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1]
    },
    xaxis: {
      type: 'datetime',
      categories: dates,

      labels:{
        datetimeUTC: false

      }
    },
    yaxis: [{


      labels:  {
        formatter: function(val) {
          return Math.round((val + Number.EPSILON) * 100) / 100
        }
      }
    
    }, {
      opposite: true,


      labels:  {
        formatter: function(val) {
          return val.toFixed(0)
        }
      }
    }],
    tooltip: {
      custom: function({dataPointIndex, w}) {
  
        var usage = w.globals.initialSeries[0].data[dataPointIndex];
        var cost = w.globals.initialSeries[1].data[dataPointIndex];

        let date_time = new Date(w.globals.seriesX[0][dataPointIndex])
        let d = date_time.toLocaleDateString("SE");
        let t = String(date_time.getHours());
        t = t.padStart(2,'0') + "-" + String((parseInt(t)+1)).padStart(2,'0');
        
        return '<div class=toolTip>' + '<p>' + d + ' ' + t + '</p><section>' +
        '<li><b>Förbrukning</b>: ' + usage + ' kWh </li> ' +
        '<li><b>Pris</b>: ' + cost + ' (öre) </li>' +
        '<li><b>Kostnad ~</b>: ' + parseFloat(cost*usage).toFixed(3) + ' öre </li></</section> ' +


        '</div>';
      }
    }
  }

  series[1].name = "Pris (öre)"
  series[1].data = cost
  series[0].name = "Förbrukning (kWh)";
  series[0].data = usage
  options.yaxis[0].tickAmount  = 9;
  options.yaxis[0].forceNiceScale = true;

  if(kwhLimit !== 0){
    options.yaxis[0].max = kwhLimit
  }
  if(costLimit !== 0){
    options.yaxis[1].max = costLimit
  }
  if(series[1].data.length < 1500){
      options.chart.type="column"
      series[0].type = "column"
      options.stroke.width= [1, 3]


  }else{
    options.stroke.width= [2, 2]

  }


  options.xaxis.categories = dates;

  series[1].max = 100;

 return (
    <ReactApexChart options={options} series={series} type="line" height={350} />
 )
}