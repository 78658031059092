import React from 'react'
import "../App.css"
import ReactApexChart from 'react-apexcharts'

const Dagspris = (data) => {
    //let k = props.data;
    let usage = data.usage
    let cost = data.cost
    let date = data.dates
    let new_dates = []
    let dayCost = []
    let dayUsage = []
    
    let lowst = 10000
    let highest = -1


    const chunkSize = 24;
    for (let i = 0; i < usage.length; i += chunkSize) {
        const chunkUsage = usage.slice(i, i + chunkSize);
        const chunkCost = cost.slice(i, i + chunkSize);

        let used = chunkUsage.reduce((pv, cv) => pv + cv, 0);
        used = Math.round(used * 100) / 100
        dayUsage.push(used)

        let sum = chunkUsage.reduce(function(r,a,i){return r+a*chunkCost[i]},0)
        sum/=100
        sum = Math.round(sum * 100) / 100
        dayCost.push(sum)
        new_dates.push(date[i].split("T")[0]);

        if(sum > highest){
          highest = sum
        }

        if(sum < lowst){
          lowst = sum
        }

        
    }
    

 
    const options= {
          chart: {
            type: "bar",
            toolbar:{
                show:false
            }

        },
          grid: {
            padding: {
             left: -5,
             right: 0
            },
            margin:{
                left: 0,
                right:0
            }
          },


          xaxis: {
            type: 'date',
            categories: new_dates
            },
   
  
          plotOptions: {
            bar: {
              dataLabels: {
                position: "top" // top, center, bottom
              }
            }
          },
          dataLabels: {
            enabled: new_dates.length < 8,
            formatter: function(val) {
              return val + "kr";
            },
            offsetY: -15,
            style: {
              fontSize: "10px",
              colors: ["#304758"]
            }
          },
          tooltip: {
            custom: function({dataPointIndex, w}) {
        
              
            var cost = dayCost[dataPointIndex];
            var z = dayUsage[dataPointIndex];

              
              return '<div> ' + cost + "kr " + "<br>" + z + " kwh" + 
      
      
              '</div>';
            }

          },

          yaxis: {
            labels: {
              show: true,
 
            }
          }
          

   
        }

    const series = [
          {
            name: "Dagspris",
            data: dayCost,
            type: 'column',
  

          }
      
          
        ]
        

    

 	return(
        <div className='centered'>
  
            <p>Billigaste dagen: {lowst} kr </p> 
    <ReactApexChart options={options} series={series} height={250} width={350}/>
            
        <p>Dyraste dagen: {highest} kr </p>


             </div>
	);
    };

export default Dagspris;
