import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";

import "react-datepicker/dist/react-datepicker.css";
import "../customDatePickerWidth.css";

   <DatePicker dateFormat="dd/MM/yyyy" />


export default function CustomDatePicker({inDate, update}) {
    useEffect(() => {
      registerLocale("en-GB", enGB);
  }, []);
 //const [date, setDate] = useState(new Date());

 return (


   <DatePicker 
   
    selected={inDate}
    onChange={(date) => {
      update(date)
    }}

    locale="en-GB"
    minDate={new Date("2022 Oct 31")}
    maxDate={new Date()}
    onFocus={e => e.target.blur()} 
    disabledKeyboardNavigation
    withPortal
    
     />

 );
}